import { v4 } from "uuid";
import Vue from "@/libraries/vue";
import axios from "@/libraries/axios";
import swal from "@/libraries/sweetalert";
import { i18n } from "@/plugins/i18n";
import { API_URL } from "@/assets/js/utils";
import { moduleDebug } from "@/assets/js/debug";
import { error } from "@/assets/js/exceptions";
import usersStore from "@/store/users";
const footprintsStore = new Vue({
    name: "FootprintsStore",
    data() {
        const data = {
            new: {
                id: "",
                name: "",
                comment: ""
            }
        };
        return data;
    },
    computed: {
        meId: () => usersStore.meId,
    },
    methods: {
        image(footprintId, userId) {
            userId = userId || this.meId;
            if (!footprintId || !userId)
                return "";
            const path = `images/footprints/${footprintId}/${userId}.jpg`;
            return `https://s3.amazonaws.com/icnitas-uploads/${path}`;
        },
        async saveImage(image, id) {
            const meId = this.meId;
            id = id || "" + v4();
            console.log("footprint saveImage");
            if (!image) {
                const ok = await swal(i18n.t("delete_image") || "Eliminar imagen?");
                if (!ok)
                    return;
                await axios.delete(API_URL + `/images/footprints/${id}`);
                return;
            }
            const filename = `${meId}.jpg`;
            const file = new File([image], filename);
            if (!file) {
                error("image not uploaded");
                return;
            }
            const formData = {
                id,
                image: await toBase64(file)
            };
            const response = await axios.post(API_URL + `/images/footprints`, formData);
            const data = response.data;
            if (data)
                swal(data);
        },
    }
});
export default footprintsStore;
moduleDebug(footprintsStore);
function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
