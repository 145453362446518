import { v4 } from "uuid";
import Vue from "@/libraries/vue";
import localesStore from "@/store/locales";
import searchStore from "@/store/search";
import footprintsStore from "@/store/footprints";
export default Vue.extend({
    name: "Home",
    data() {
        const data = {
            to: "",
            toSearch: "",
            footprints: [],
        };
        return data;
    },
    computed: {
        footprintId() {
            const to = this.to;
            return to && to.id;
        },
        name() {
            return this.toSearch || this.to;
        },
    },
    watch: {
        async toSearch(toSearch) {
            console.log("toSearch", toSearch);
            this.footprints = await searchStore.search(toSearch);
        },
    },
    methods: {
        see() {
            const footprintId = this.footprintId;
            this.$router.push(localesStore.localePath("/" + footprintId));
        },
        openNew() {
            const toSearch = this.toSearch;
            footprintsStore.new = {
                id: v4(),
                name: toSearch,
                comment: "",
            };
            this.$router.push(localesStore.localePath("/new"));
        },
    },
});
